<template>
  <div class="container">
    <img src="@/assets/images/noSecret.png" alt="" class="secret-img" v-if="showMm">
    <img src="@/assets/images/mm-and.png" alt="" class="secret-img" v-else>

    <div class="text-box" v-if="noWX">
        <div class="icon-box" :style="{marginTop:`${height}px`}">
            <div class="icon">
                 <van-icon name="arrow-left" color="#000" size="28" @click="back()"/>
            </div>
            <div class="text">
              免密支付
            </div>
        </div>
    </div>

    <div class="btn" :class="{'disabled': !isSet}" @click="toSecret"  v-if="showMm">{{isSet ? '去设置' : '您已开通免密支付'}}</div>
  </div>
</template>
<script>
import { getEnv,initIosAndAndroidEnv,getIosAndAndroidData,initAlipayEnv} from "../../../utils/index"
import { getAutoPay } from "@/api/app";
import { Toast } from 'vant';
export default {
  data() {
    return {
      isSet: false,
      height:'30',
      noWX: true,
      loading:true,
      showMm: true
    };
  },
  computed:{
      
  },
  mounted(){
    setTimeout(() => {
      this.loading = false
    }, 300);
    this.init()
    this.getData()


    let that = this
    window.addEventListener('pageshow', function (e) {
      setTimeout(() => {
        that.getData()
        that.init()
      }, 5000);
    })
  },
  methods: {
      // 返回
      back() {
        let ENV = getEnv();
        if (ENV === "ios" || ENV === "andriod") {
            initIosAndAndroidEnv(ENV, "Other/closeNav", "back-20220914");
        }
        console.log("只有一个页面")
      },
      init(){
        let ENV = getEnv();
        if (ENV === "ios" || ENV === "andriod") {
            this.noWX = true
            initIosAndAndroidEnv(ENV, "navigation/visible", "family-20230309", {
              show: false,
            });
            // 状态栏颜色深浅
            initIosAndAndroidEnv(ENV, "statusBar/textColor", "family-20230309", {
              dark: false, //false 浅色
            });
            //获取状态栏高度
            initIosAndAndroidEnv(ENV, "Other/getStatusbarHeight", "height-20230309");
            getIosAndAndroidData(ENV, [
              {
                id: "height-20230309",
                callback: this.heightData,
              },
            ]);

            initIosAndAndroidEnv(ENV,'Other/getDeviceInfo','device-230314')
            getIosAndAndroidData(ENV,[
                {
                    id:'device-230314',
                    callback: this.getDeviceInfo,
                }
            ])
        } else if(ENV === 'wx'){
            this.noWX = false
        } else if(ENV === 'alipay'){
          this.noWX = false
          initAlipayEnv()
        }
    },
    getDeviceInfo(res){
      // 当前版本1.9.2.0
      const currentV = 1090300
      let ENV = getEnv();
      let data = res.data
      if(parseFloat(data.appVersionCode) < currentV && ENV == "andriod"){
        this.showMm = false
      } else {
        this.showMm = true
      }
    },
    heightData(res){
      this.height = Number(res.height);
    },
    getData(){
      getAutoPay({
        needSign: true
      }).then((res) => {
        if (res.success) {
          this.isSet = res.data.aliPayEnable == 0
        }
      });
    },
    toSecret(){
      if(this.isSet){
        Toast({
          message: '跳转中...',
          forbidClick: true,
        });
        window.location.href = '/app/payNoSecret.html'
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #fff;
  overflow: scroll;
  .secret-img{
    max-width: 100%;
    height: auto;
  }

    .btn {
        height: 48px;
        padding: 13px 25px;
        margin: 0 16px;
        color: #fff;
        font-size: 16px;
        border-radius: 4px;
        background-color: #1777FF;
        &.disabled{
          background: #ddd;
        }
    }
  .text-box {
      width: 100%;
      text-align: center;
      position: absolute;
      top: 0;
      color: #fff;
      .icon-box {
        .icon {
          z-index: 10000;
          position: fixed;
          text-align: left;
          padding: 20px 0 15px 15px;
        }
        .text{
          width: 100%;
          font-size: 20px;
          color: #000;
          position: fixed;
          text-align: center;
          padding: 20px 0 15px 0;
        }
      }
  }
}
</style>
