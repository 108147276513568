import request from '@/global/xhr'
// import qs from 'qs'

export function getUserAccount(data){
  return request({
    url: '/api/pass/token/pass/account/info',
    method: 'get',
    data: data
  })
}
export function getBalanceList(data){
  return request({
    url: '/api/pass/token/user/info/balance/list',
    method: 'get',
    data: data
  })
}

export function getAutoPay(data){
  return request({
    url: '/appRest/rest/api/pass/autoPay/queryConfig',
    method: 'get',
    data: data
  })
}

//获取开通的城市列表
export function getCity() {
  return request({
      url: '/appRest/rest/api/pass/orderClueRecord/getCities',
      method: 'get'
  })
}
//留资
export function getinviteData(data) {
  return request({
      url: '/appRest/rest/api/pass/orderClueRecord/save',
      method: 'post',
      data,
      tokenType:'header'
  })
}

